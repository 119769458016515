<template>
  <div class="page-slideout">
    <div class="section section-separate">
      <div class="container pt-0">
        <form class="content register-content">
          <ul class="nav nav-register mb-0 w-520 pl-0">
            <li class="goback moving-left">
              <a href @click="goBack()">
                <span class="icons icon-arrow-left"></span>
              </a>
            </li>
          </ul>
          <div class="scrollbar-inner">
            <div class="form-box">
              <h3 class="title-md">{{ $t("SignupStep.property_type_title") }}</h3>
              <label class="light mb-3">
                {{
                $t("SignupStep.select_property_type")
                }}
              </label>
              <div
                class="dropdown select"
                @click="getfocus('propertytype')"
                @mouseleave="mouseOver('propertytype')"
              >
                <a id="propertytype">
                  <v-select
                    v-model="signupData.property_type"
                    :items="itemsPropertyType"
                    item-text="name"
                    item-value="_id"
                    label
                    :placeholder="$t('SignupStep.select_one')"
                    :no-data-text="$t('SignupStep.nodata')"
                    color="pink"
                    solo
                    flat
                    dense
                    single-line
                    hide-details
                  >
                    <template v-slot:item="data">
                      <v-list-item-content
                        @mouseenter="getfocus('propertytype')"
                        @mouseleave="mouseOver('propertytype')"
                      >
                        <v-list-item-title v-html="data.item.name" class="title-signup-sj"></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.description"
                          class="label-signup-obj"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                    <template v-slot:no-data>
                      <v-btn
                        @click="initProperty"
                        text
                        color="pink"
                        style="width: 100%"
                      >{{ $t("SignupStep.btn_refresh") }}</v-btn>
                    </template>
                  </v-select>
                </a>
              </div>
            </div>

            <div class="form-box">
              <h3 class="title-md">{{ $t("SignupStep.propery_num_title") }}</h3>
              <div class="form-group">
                <label class="light">
                  {{
                  $t("SignupStep.select_num_title")
                  }}
                </label>

                <div class="quantity d-flex">
                  <label>{{ $t("SignupStep.select_property") }}</label>
                  <div class="d-flex flex-row justify-end align-center">
                    <v-btn
                      outlined
                      icon
                      @click="decreaseNumber"
                      :disabled="btnDecrease"
                      class="hover_numberbtn"
                    >
                      <v-icon x-small>mdi-minus</v-icon>
                    </v-btn>
                    <span
                      id="qty1"
                      class="input-touchspin numbersOnly text-center mx-5"
                      type="text"
                      size="2"
                      name="qty"
                    >{{ signupData.property_number }}</span>
                    <v-btn
                      outlined
                      icon
                      @click="increaseNumber"
                      :disabled="btnIncrease"
                      class="hover_numberbtn"
                    >
                      <v-icon x-small>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-buttons d-flex justify-end">
            <div class="buttons pa-0">
              <button
                type="button"
                class="btn gl-bt hover_btn"
                style="text-transform: capitalize !important"
                @click="checkBeforeAddPropertyFunc()"
                :disabled="!signupData.property_type"
              >
                <span>{{ $t("SignupStep.btn_next") }}</span>
              </button>
            </div>
          </div>
        </form>

        <div class="sidebar">
          <div class="inner pt-0">
            <img
              class="img-align-top"
              src="@/assets/img/thumb/Welcome1.gif"
              :style="styleOfRightImg"
              alt
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import swal from "sweetalert";

export default {
  name: "PropertyTypeScreen",
  metaInfo: {
    title: "register (4-6)",
    titleTemplate: "%s | Aigent"
  },
  data: () => ({
    stepwh_signup: 0,
    itemsPropertyType: [],
    signupData: {
      property_type: "",
      property_number: 1
    },
    btnDecrease: true,
    btnIncrease: false,
    btnNext: true,
    value_element: "",
    pathrightphoto: `${process.env.VUE_APP_PIC_API}/src/aigent_signup_bg.jpg`,
    propertyData: [],
    styleOfRightImg: "object-fit: cover !important;",
    user_email: "",
    userToken: "",
    newPropertyId: "",
    propertyOfUser: []
  }),
  created() {
    const self = this;
    self.user_email = localStorage.getItem("userEmail") || "";
    self.userToken = self.$route.params.userToken;
    self.stepwh_signup = Number(localStorage.getItem("SignupStep")) || 0;
    if (
      (localStorage.getItem("Token") == "" || !localStorage.getItem("Token")) &&
      self.userToken
    ) {
      localStorage.setItem("Token", self.userToken);
      self.changeLanguageData();
    }  else if (self.userToken) {
      self.changeLanguageData();
    } else if (!self.userToken) {
      self.gotosignup();
    }
    if (self.$store.state.property_type)
      localStorage.setItem("PropertyType", self.$store.state.property_type);
    if (self.$store.state.property_number)
      localStorage.setItem("PropertyNumber", self.$store.state.property_number);
    if (localStorage.getItem("PropertyType"))
      self.$store.state.property_type = localStorage.getItem("PropertyType");
    if (localStorage.getItem("PropertyNumber"))
      self.$store.state.property_number = localStorage.getItem(
        "PropertyNumber"
      );
  },
  mounted() {
    EventBus.$on("refreshRightPhoto", this.changeLanguageData);
    this.colorHover();
  },
  watch: {
    "signupData.property_type"() {
      const self = this;
      if (
        self.signupData.property_type === "" ||
        self.signupData.property_number < 1
      )
        self.btnNext = true;
      else self.btnNext = false;
    },
    "signupData.property_number"() {
      const self = this;
      if (self.signupData.property_number < 2) self.btnDecrease = true;
      else self.btnDecrease = false;
    }
  },
  methods: {
    colorHover() {
      const button = document.querySelector(".hover_btn");
      button.addEventListener("mousemove", e => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    getfocus(val) {
      var element = document.getElementById(val);
      if (this.value_element === "") {
        element.classList.add("border-pink-focus");
        this.value_element = val;
      } else if (this.value_element) {
        document
          .getElementById(this.value_element)
          .classList.remove("border-pink-focus");
        element.classList.add("border-pink-focus");
        this.value_element = val;
      }
    },
    mouseOver(val) {
      var element = document.getElementById(val);
      if (this.value_element) {
        document
          .getElementById(this.value_element)
          .classList.remove("border-pink-focus");
        element.classList.remove("border-pink-focus");
        this.value_element = "";
      }
    },
    gotosignin() {
      const item = { link: "signin", name: "SigninScreen" };
      EventBus.$emit("changePathname", item);
    },
    gotosignup() {
      var item = { link: "signup", name: "SignupScreen" };
      EventBus.$emit("changePathname", item);
    },
    increaseNumber() {
      const self = this;
      self.signupData.property_number = self.signupData.property_number + 1;
    },
    decreaseNumber() {
      const self = this;
      self.signupData.property_number = self.signupData.property_number - 1;
    },
    changeLanguageData() {
      const self = this;
      self.initGetUser();
      self.initPropertyData();
      self.initProperty();
    },
    goBack() {
      EventBus.$emit("goBackPage");
    },
    nextstep(num, id) {
      const self = this;
      self.stepwh_signup = num;
      self.gotobasic(self.userToken, id);
    },
    gotobasic(token, id) {
      const self = this;
      var item = {
        link: `signup/basic/${token}/${id}`,
        name: "SignupBasicInformationScreen",
        params: { userToken: token || self.userToken, propertyId: id }
      };
      EventBus.$emit("changePathname", item);
    },
    async initProperty() {
      const self = this;
      var temp = [];
      self.itemsPropertyType = [];
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + "/setting/property_type",
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language
            }
          }
        );
        if (res.status == 200) {
          res.data.result.map(el => {
            if (el?.value && (el?.description || el?.desciption))
              temp.push({
                _id: el._id,
                name: el.value || "",
                value: el._id,
                description:
                  el?.description ||
                  el?.desciption ||
                  self.$t("SignupStep.no_property_type_description")
              });
          });
        }
      } catch (error) {
        swal(
          self.$t("Alert.warn_title"),
          error?.response?.data?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000
          }
        ).then(() => self.gotosignup());
        console.log(error?.response?.data?.message || error);
      } finally {
        self.itemsPropertyType = temp;
      }
    },
    async initPropertyData() {
      const self = this;
      self.propertyData = [];
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + "/property",
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language
            }
          }
        );
        if (res.status == 200) {
          self.propertyData = res.data.result;
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.warn_title"),
          error?.response?.data?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000
          }
        );
        if (error?.response?.data?.message == "Token invalid") {
          self.gotosignup();
        }
      }
    },
    checkBeforeAddPropertyFunc() {
      const self = this;
      self.addProperty();
    },
    async addProperty() {
      const self = this;
      const obj = {
        property_type:
          self.signupData.property_type || localStorage.getItem("PropertyType"),
        property_number:
          self.signupData.property_number ||
          localStorage.getItem("PropertyNumber")
      };
      var tempId = "";
      if (obj.property_type && obj.property_number) {
        EventBus.$emit("loadingtillend");
        try {
          const res = await self.axios.post(
            process.env.VUE_APP_API + "/property",
            obj,
            {
              headers: {
                Authorization:
                  self?.userToken ||
                  localStorage?.getItem("Token") ||
                  self?.$store?.state?.resSignUpData?.access_token,
                "X-Language-Code":
                  localStorage?.getItem("language") ||
                  self?.$store?.state?.language
              }
            }
          );
          if (res.status == 200) {
            EventBus.$emit("endloading");
            tempId = res?.data?.result?._id;
            self.newPropertyId = tempId;
            if (obj.property_number == 1)
              self
                .initGetUser()
                .then(() =>
                  self.sendVerify(self.userToken, self.newPropertyId)
                );
            else {
              self.sendEmailAddProperty(
                1,
                obj.property_number,
                self.newPropertyId
              );
              for (var i = 2; i <= obj.property_number; i++) {
                self.addPropertyNext(obj.property_type, i, obj.property_number);
              }
            }
          }
        } catch (error) {
          EventBus.$emit("endloading");
          console.log(error?.response?.data?.message || error);
          swal(
            self.$t("Alert.warn_title"),
            error?.response?.data?.message || self.$t("Alert.tryagain"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000
            }
          );
          if (error?.response?.data?.message == "Token invalid") {
            self.gotosignup();
          }
        }
      } else {
        EventBus.$emit("endloading");
        swal(
          self.$t("Alert.warn_title"),
          self.$t("Alert.fillinfo"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000
          }
        );
      }
    },
    async addPropertyNext(type, property_no, property_num) {
      const self = this;
      const obj = {
        property_type: type
      };
      var propertyId = null;
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.post(
          process.env.VUE_APP_API + "/property/next",
          obj,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language
            }
          }
        );
        if (res.status == 200) {
          EventBus.$emit("endloading");
          propertyId = res?.data?.result?._id;
          // console.log("no: ", property_no);

          self.sendEmailAddProperty(property_no, property_num, propertyId);
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.warn_title"),
          error?.response?.data?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000
          }
        );
      }
    },
    async sendEmailAddProperty(property_no, property_num, propertyId) {
      const self = this;
      const objEmail = {
        email: self?.user_email || localStorage?.getItem("userEmail"),
        link: `${window.location.origin || process.env.VUE_APP_WEB}/signup/basic/${self.userToken}/${propertyId}`,
        subject: `Information Property ${property_no}`,
        button: "ADD PROPERTY"
      };
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.post(
          process.env.VUE_APP_API + "/property/sent_mail_exit",
          objEmail,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language
            }
          }
        );
        if (res?.status == 200) {
          if ((property_no = property_num)) {
            setTimeout(() => {
              self.nextstep(1, self.newPropertyId);
              EventBus.$emit("endloading");
            }, 2000);
          }
        } else {
          EventBus.$emit("endloading");
          swal(
            self.$t("Alert.warn_title"),
            res?.data?.message || self.$t("Alert.tryagain"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000
            }
          );
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.warn_title"),
          error?.response?.data?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000
          }
        );
      }
    },
    async initGetUser() {
      const self = this;
      var temp = [];
      try {
        const res = await self.axios.get(process.env.VUE_APP_API + "/my_user", {
          headers: {
            Authorization:
              self?.userToken ||
              localStorage?.getItem("Token") ||
              self?.$store?.state?.resSignUpData?.access_token,
            "X-Language-Code":
              localStorage?.getItem("language") || self?.$store?.state?.language
          }
        });
        if (res.status == 200) {
          self.user_email = res?.data?.result?.email;
          self.propertyOfUser = res?.data?.result?.property[0];
          localStorage.setItem("userEmail", self.user_email);
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
      } finally {
      }
    },
    async sendVerify(accessToken, tempId) {
      const self = this;
      const numProperty = self?.propertyOfUser?.properties?.length || 1;
      const obj = {
        email: self?.user_email || localStorage?.getItem("userEmail"),
        link: `${window.location.origin || process.env.VUE_APP_WEB}/signup/basic/${accessToken}/${tempId}`,
        subject: `Information Property ${numProperty}`,
        button: "ADD PROPERTY"
      };
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.post(
          process.env.VUE_APP_API + "/property/sent_mail_exit",
          obj,
          {
            headers: {
              Authorization:
                accessToken ||
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language
            }
          }
        );
        if (res?.status == 200) {
          setTimeout(() => {
            self.nextstep(1, tempId);
            EventBus.$emit("endloading");
          }, 2000);
        } else {
          EventBus.$emit("endloading");
          swal(
            self.$t("Alert.warn_title"),
            res?.data?.message || self.$t("Alert.tryagain"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000
            }
          );
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.warn_title"),
          error?.response?.data?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000
          }
        );
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../styles/bootstrap/global.css";

.moving-left {
  /* height: 100px; */
  /* position: relative; */
  transition: transform 0.3s ease-in-out;
  transform: translateX(0px);
  /* width: 100px; */
}

.moving-left:hover {
  transform: translateX(-10px);
}

.hover_numberbtn:hover {
  border: solid 1px transparent;
  color: #fff !important;
  background-image: linear-gradient(#f9f9f9, #f9f9f9),
    radial-gradient(circle at top left, #f00, #3020ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
}

.btn.gl-bt {
  overflow: hidden;
  border: 0;
  background-color: var(--pink);
  padding: 0 0.625rem;
}

.btn.gl-bt:hover {
  background-color: #652b80;
}

.btn.gl-bt::before {
  top: calc(var(--y, 0) * 1px - 50px);
  left: calc(var(--x, 0) * 1px - 50px);
  --size: 0;
  content: "";
  position: absolute;
  width: var(--size);
  height: var(--size);
  background: radial-gradient(circle closest-side, #e8107f, transparent);
  transform: translate(-50%, -50%);
  transition: width 0.2s ease, height 0.2s ease;
}

.gl-bt:hover:before {
  --size: 350px;
}

.border-pink-focus {
  // background-color: #fff;
  border-color: var(--pink) !important;
}

.v-input__slot {
  border: 1px solid var(--border-gray) !important;
}

.list__tile {
  height: 5rem !important;
}

.v-list {
  display: block;
  padding: 0 !important;
  position: static;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  will-change: box-shadow;
}

// .v-menu__content {
//   position: absolute !important;
//   float: left !important;
//   max-width: 12rem !important;
//   min-width: 29.375rem !important;
//   margin-top: 48px !important;
//   left: 18.438rem !important;
//   bottom: 100% !important;
//   font-size: 1rem !important;
//   color: #212529 !important;
//   text-align: left !important;
//   list-style: none !important;
//   background-clip: padding-box !important;
//   border: 1px solid rgba(0,0,0,.15) !important;

//   border-radius: 5px !important;
//   border-color: #BDBDBD !important;
//   background-color: #fff !important;
//   border-top: 0 !important;
//   width: 100% !important;
//   margin: -3px 0 0 !important;
//   padding: 11px 10px 10px !important;
//   height: 100% !important;
//   max-height: 18.75rem !important;
//   overflow-x: auto !important;
//   -webkit-overflow-scrolling: touch !important;
//   box-shadow: none !important;
// }

// .v-list-item__title {
//   font-size: var(--f-14) !important;
//   font-weight: 400 !important;
//   color: #000 !important;
// }

// .theme--light.v-list-item .v-list-item__subtitle, .theme--light.v-list-item .v-list-item__action-text {
//   margin: 0 !important;
//   font-size: var(--f-12) !important;
//   color: var(--gray) !important;
//   font-weight: 300 !important;
// }
</style>

<style lang="scss">
@import "../../../styles/_variables.scss";
</style>

<style>
@media (min-width: 768px) and (max-width: 1024px) {
  .siq_bR {
    width: 3.5rem !important;
    height: 2rem !important;
    bottom: 7.5rem !important;
    right: 0.625rem !important;
  }

  #zsiq_float img {
    width: 3.5rem !important;
    height: 2rem !important;
    object-fit: contain !important;
  }
}
@media (min-width: 321px) and (max-width: 767px) {
  .siq_bR {
    width: 4.5rem !important;
    height: 3rem !important;
    bottom: 6rem !important;
    right: 0.625rem !important;
  }

  #zsiq_float img {
    width: 4.5rem !important;
    height: 3rem !important;
    object-fit: contain !important;
  }
}
@media (max-width: 320px) {
  .siq_bR {
    width: 3.5rem !important;
    height: 2rem !important;
    bottom: 6rem !important;
    right: 0.625rem !important;
  }

  #zsiq_float img {
    width: 3.5rem !important;
    height: 2rem !important;
    object-fit: contain !important;
  }
}
</style>
